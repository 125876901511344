<template>
    <div id = "Empty">
        <Header ref="Header"/>
    </div>
</template>


<script>
import Header from '@/components/Header.vue'

export default {
    name: 'Empty',
    components: {
        Header,
    },
    data(){
        return{
        }
    },
    methods:{
        logCallback(){},
    },
    created()
    {
        if(this.$route.params && this.$route.params.isLogOut && this.$route.params.isLogOut != undefined && this.$route.params.isLogOut == "Y"){
            this.$router.push(
            {
                name : 'Home',
                params:{
                    isLogOut: 'Y',
                }
            });
        }
    },
    mounted()
    {
    }
}
</script>
